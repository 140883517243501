import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Swiper, Pagination} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

class progressSlider {
    constructor(container) {
        this.container = container;

        this.sliderSelector = container.querySelector('[data-project-construction-progress-slider]');
        this.sliderList = this.sliderSelector.querySelector('.swiper-wrapper');
        this.leftArrow = container.querySelector('[data-project-construction-progress-prev]');
        this.rightArrow = container.querySelector('[data-project-construction-progress-next]');

        this.pagination = container.querySelector('[data-project-construction-progress-pagination]');

        this.year = 0;

        this.init();
    }

    init() {
        this.initAlbums();
        this.initSlider();
        this.initInputs();
        this.bindYears();
    }

    initAlbums() {
        this.albums = [];
        this.container.querySelectorAll('[data-album]').forEach((album) => {
            this.albums.push({
                element: album.cloneNode(true),
                year: parseInt(album.dataset.year, 10),
            });
        });
    }

    initInputs() {
        this.yearSelect = this.container.querySelector('[data-project-construction-year-select]');
        if (!this.yearSelect) {
            console.log('progressSlider: Could not found year select');
        }
    }

    bindYears() {
        const instance = this;
        if (this.yearSelect) {
            this.yearSelect.addEventListener('change', (e) => {
                instance.year = e.target.value;
                instance.year = parseInt(instance.year.replace(/([^\d]*)/g, ''), 10);
                instance.rebuild();
            });
        }
    }

    rebuild() {
        this.destroy();
        this.clear();
        this.append();
        this.initSlider();
        window.refreshFsLightbox();
    }

    destroy() {
        this.slider.destroy();
    }

    clear() {
        this.container.querySelectorAll('[data-album]').forEach(album => album.remove());
    }

    append() {
        this.albums.forEach((album) => {
            if (this.year && this.year !== album.year) {
                return;
            }

            const clonedAlbum = album.element.cloneNode(true);
            this.sliderList.append(clonedAlbum);
        });
    }

    initSlider() {
        this.slider = new Swiper(this.sliderSelector, {
            modules: [Navigation, Pagination],
            slidesPerView: "auto",
            grabCursor: true,
            navigation: {
                prevEl: this.leftArrow,
                nextEl: this.rightArrow,
            },
            pagination: {
                el: this.pagination,
                type: "fraction",
            },
            breakpoints: {
                0: {
                    spaceBetween: 20,
                },
                768: {
                    spaceBetween: 40,
                },
                1280: {
                    spaceBetween: 60,
                },
            },
            on: {
                init: (swiper) => {
                    const arrows = this.container.querySelector('.arrows');
                    if (swiper.snapGrid.length > 1) {
                        arrows.classList.remove('_hidden');
                    } else {
                        arrows.classList.add('_hidden');
                    }
                }
            }
        });
    }
}

onDomReady(() => {
    const progressContainer = document.querySelector('[data-project-construction-progress]');
    if (progressContainer) {
        new progressSlider(progressContainer);
    }
});