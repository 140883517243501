import bigCirclePath from '!file-loader!../../images/svg/shadowed-big-circle.svg';
import smallCirclePath from '!file-loader!../../images/svg/shadowed-small-circle.svg';
import { MapObject } from './MapObject';

export default class ProjectMap {
  constructor(rootElement, settings) {
    this.settings = {
      lat: 0,
      lng: 0,
      zoom: 14,
      icon: null,
      color: null,
    };
    Object.assign(this.settings, rootElement.dataset, settings);
    this.objects = [];
    this.rootElement = rootElement;
    this.mapElement = rootElement.querySelector('[data-project-map-canvas]');
    this.map = null;
    this.selectedCategories = [];
    this.cluster = null;
    this.initMapPending();
    this.initFilter();
  }

  getLat() {
    return parseFloat(this.settings.lat);
  }

  getLng() {
    return parseFloat(this.settings.lng);
  }

  getZoom() {
    return parseInt(this.settings.zoom, 10);
  }

  getIcon() {
    return this.settings.icon;
  }

  getColor() {
    return this.settings.color ? this.settings.color : '#8EBA3E';
  }

  initMapPending() {
    if (window.ymaps) {
      this.initMap();
      return;
    }
    setTimeout(() => {
      this.initMapPending();
    }, 500);
  }

  initMap() {
    ymaps.ready(() => {
      const rect = this.mapElement.getBoundingClientRect();
      this.map = new ymaps.Map(this.mapElement, {
        center: [this.getLat(), this.getLng()],
        zoom: this.getZoom(),
        controls: ['zoomControl'],
      }, {
        zoomControlPosition: { right: 10, top: 'auto', left: 'auto', bottom: (rect.height/2 - 30) + 'px' },
        zoomControlSize: 'small',
      });
      this.map.behaviors.disable('scrollZoom');
      if (window.innerWidth <= 1279) {
        this.map.behaviors.disable('drag');
      }
      this.initMainPlacemark();
      this.initObjects();
    });
  }

  initMainPlacemark() {
    const hintElement = this.rootElement.querySelector('[data-project-map-hint]');
    let hintLayout = null;
    if (hintElement) {
      hintLayout = ymaps.templateLayoutFactory.createClass(
        hintElement.innerHTML, {
          /**
           * Defining the getShape method,
           * which will return the size of the hint layout.
           * This is necessary in order for the hint to automatically
           * move its position when going off the map.
           */
          getShape: function () {
            const el = this.getElement();
            let result = null;
            if (el) {
              const firstChild = el.firstChild;
              result = new ymaps.shape.Rectangle(
                new ymaps.geometry.pixel.Rectangle([
                  [0, 0],
                  [firstChild.offsetWidth, firstChild.offsetHeight],
                ]),
              );
            }
            return result;
          },
        },
      );
    }

    const mainPlacemark = new ymaps.Placemark([this.getLat(), this.getLng()], {

    }, {
      // Опции.
      // Необходимо указать данный тип макета.
      iconLayout: 'default#imageWithContent',
      // Своё изображение иконки метки.
      iconImageHref: bigCirclePath,
      // Размеры метки.
      iconImageSize: [59, 59],
      // Смещение левого верхнего угла иконки относительно
      // её "ножки" (точки привязки).
      iconImageOffset: [-29, -29],
      // Смещение слоя с содержимым относительно слоя с картинкой.
      iconContentOffset: [0, 0],
      // Макет содержимого.
      iconContentLayout: ymaps.templateLayoutFactory.createClass(
        `<div class="project-map__main-icon">
            <img class="project-map__main-icon-img" src="${this.getIcon()}"/>
         </div>`,
      ),
      hint: hintLayout ? '' : undefined,
      hintLayout: hintLayout,
    });
    this.map.geoObjects.add(mainPlacemark);
  }


  initObjects() {
    const elements = this.rootElement.querySelectorAll('[data-project-map-object]');
    elements.forEach((element) => {
      const mapObject = new MapObject(element);
      this.objects.push(mapObject);
    });
    this.cluster = new ymaps.Clusterer({
      hasBalloon: false,
      clusterIcons: [
        {
          href: '',
          size: [40, 40],
          offset: [0, 0],
        },
      ],
      clusterIconContentLayout: ymaps.templateLayoutFactory.createClass(
        `<div class="project-map__cluster-icon">
            {{ properties.geoObjects.length }}
         </div>`,
      ),
      groupByCoordinates: false,
    });
    const placemarks = [];
    this.objects.forEach((mapObject) => {
      const placemark = this.initPlacemarkForObject(mapObject);
      placemarks.push(placemark);
    });
    this.cluster.add(placemarks);
    this.map.geoObjects.add(this.cluster);
  }

  initPlacemarkForObject(mapObject) {
    if (window.innerWidth >= 768) {
      this.iconImageSize = [46, 46];
      this.iconImageOffset = [-23, -23];
    } else {
      this.iconImageSize = [36, 36];
      this.iconImageOffset = [-18, -18];
    }
    const iconPlacemark = new ymaps.Placemark([mapObject.getLat(), mapObject.getLng()], {
      hintContent: mapObject.getName(),
    }, {
      // Опции.
      // Необходимо указать данный тип макета.
      iconLayout: 'default#imageWithContent',
      // Своё изображение иконки метки.
      iconImageHref: smallCirclePath,
      // Размеры метки.
      iconImageSize: this.iconImageSize,
      // Смещение левого верхнего угла иконки относительно
      // её "ножки" (точки привязки).
      iconImageOffset: this.iconImageOffset,
      // Смещение слоя с содержимым относительно слоя с картинкой.
      iconContentOffset: [0, 0],
      // Макет содержимого.
      iconContentLayout: ymaps.templateLayoutFactory.createClass(
        `<div class="project-map__object-icon">
            <img class="project-map__object-icon-img" src="/static/images/svg/infrastructure/${mapObject.getCategory()}.svg"/>
         </div>`,
      ),
    });
    mapObject.setPlacemark(iconPlacemark);
    return iconPlacemark;
  }

  initFilter() {
    const allFiltersButton = document.querySelector('[data-project-map-category-all]');
    allFiltersButton.addEventListener('click', (e) => {
      document.querySelectorAll('[data-project-map-category-input]').forEach((filterInput) => {
        filterInput.checked = false;
        filterInput.dispatchEvent(new Event('change'));
      });

      allFiltersButton.classList.remove('_not-checked');
    });

    document.querySelectorAll('[data-project-map-category-input]').forEach((filterInput) => {
      filterInput.addEventListener('change', (e) => {
        this.onFilterChange();
      });
    });
  }

  onFilterChange() {
    this.selectedCategories = [];
    document.querySelectorAll('[data-project-map-category-input]').forEach((filterInput) => {
      if (filterInput.checked) {
        this.selectedCategories.push(filterInput.value);

        if (document.querySelector('[data-project-map-category-all]')) {
          document.querySelector('[data-project-map-category-all]').classList.add('_not-checked');
        }
      }
    });
    this.changeObjectsVisibility();
  }

  changeObjectsVisibility() {
    const currentPlacemarks = this.cluster.getGeoObjects();
    const placemarksForRemove = [];
    const placemarksForAdd = [];

    const alreadyAdded = (placemark) => {
      let result = false;
      currentPlacemarks.forEach((currentPlacemark) => {
        if (currentPlacemark === placemark) {
          result = true;
        }
      });
      return result;
    };
    const allowedAll = this.selectedCategories.length === 0;
    this.objects.forEach((mapObject) => {
      const placemark = mapObject.getPlacemark();
      if (allowedAll || this.selectedCategories.indexOf(mapObject.getCategory()) !== -1) {
        if (!alreadyAdded(placemark)) {
          placemarksForAdd.push(placemark);
        }
      } else if (alreadyAdded(placemark)) {
        placemarksForRemove.push(placemark);
      }
    });
    this.cluster.remove(placemarksForRemove);
    this.cluster.add(placemarksForAdd);
  }
}