import {onDomReady} from "../../components/dynamic/observer";

class HistoryProgressBar {
    offsetY = 0;
    constructor(container) {
        this.container = container;
        this.items = this.container.querySelectorAll('[data-history-item]');
        this.progressBar = this.container.querySelector('[data-history-progress-bar]');

        this.initSizes();
        this.eventListeners();
        this.update();
    }

    initSizes() {
        this.containerHeight = this.container.offsetHeight;
        // this.containerTop = this.container.offsetTop;
        // this.containerBottom = this.containerTop + this.containerHeight;
        this.halfScreen = window.innerHeight / 2;
    }

    update() {
        this.computeStyles();
        this.setStyles();
        this.handleItems();

        window.requestAnimationFrame(this.update.bind(this));
    }

    eventListeners() {
        document.addEventListener('scroll', (e) => {
            this.offsetY = window.scrollY;
        });
    }

    computeStyles() {
        const containerBounds = this.container.getBoundingClientRect();
        if (this.halfScreen <= containerBounds.top) {
            this.fillPercent = 0;
        } else if (this.halfScreen >= containerBounds.bottom) {
            this.fillPercent = 100;
        } else {
            this.fillPercent = ((this.containerHeight - (containerBounds.bottom - this.halfScreen)) / this.containerHeight) * 100;
        }
    }

    setStyles() {
        this.progressBar.style.height = `${this.fillPercent}%`
    }

    handleItems() {
        this.items.forEach((item, index) => {
            if (index > 0) {
                const itemBounds = item.getBoundingClientRect();
                if (this.halfScreen <= itemBounds.top) {
                    item.classList.remove('_active');
                } else {
                    item.classList.add('_active');
                }
            }
        });
    }
}

onDomReady(() => {
    const element = document.querySelector('[data-history-list]');
    if (element) {
        const historyProgressBar = new HistoryProgressBar(element);
    }
});