import {onDomReady} from "../../components/dynamic/observer";

onDomReady(function() {
    document.querySelectorAll('[data-tags-more]').forEach((element) => {
        const tagsList = element.closest('[data-tags-container]');

        element.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
        });

        element.addEventListener('mouseenter', (e) => {
            e.preventDefault();
            e.stopPropagation();
            tagsList.classList.add('_visible');
        });

        element.addEventListener('mouseleave', (e) => {
            e.preventDefault();
            e.stopPropagation();
            tagsList.classList.remove('_visible');
        });
    })
});