import {onDomReady} from '../../components/dynamic/observer';
import {scrollToTarget} from "../../components/scroll/scroll";

class Header {
    constructor(selector) {
        this.header = selector;
        this.headerContainer = this.header.querySelector('[data-header-container]');
        this.headerHeight = this.headerContainer.offsetHeight;

        this.hamburger = selector.querySelector('[data-hamburger]');
        this.hero = document.querySelector('[data-hero]');
        if (this.hero) {
            this.heroHeight = this.hero.offsetHeight;
        }

        // this.footer = document.querySelector('[data-footer]');

        this.isOpened = false;
        this.scrollTop = 0;

        this.eventListeners();
    }

    open() {
        this.isOpened = true;
        this.toggleOpened();
    }

    close() {
        this.isOpened = false;
        this.toggleOpened();
    }

    toggleOpened() {
        this.header.classList.toggle('_opened');
        this.hamburger.classList.toggle('_opened');
    }

    handleHeader() {
        const instance = this;
        if (instance.scrollTop > instance.headerHeight) {
            instance.header.classList.add('_sticky');
        } else {
            instance.header.classList.remove('_sticky', '_up');
        }

        if (instance.scrollTop < instance.lastScrollTop && instance.scrollTop > 0) {
            // scroll up
            instance.header.classList.add('_up');
        } else {
            // scroll down
            instance.header.classList.remove('_up');
        }

        if (instance.heroHeight) {
            if (instance.scrollTop > 0) {
                instance.header.classList.remove('_transparent');
            } else {
                instance.header.classList.add('_transparent');
            }
        }
    }

    eventListeners() {
        document.addEventListener('click', (e) => {
            if (this.isOpened && e.target !== this.header) {
                if (e.target) {
                    this.close();
                }
            }
        });

        if (this.hamburger) {
            this.hamburger.addEventListener('click', () => {
                this.isOpened ? this.open() : this.close();
            });
        }

        window.addEventListener('scroll', () => {
            const instance = this;
            instance.scrollTop = window.scrollY;
            instance.handleHeader();
            instance.lastScrollTop = instance.scrollTop;
        });
    }
}

onDomReady(() => {
    const headerSelector = document.querySelector('[data-header]');
    if (headerSelector) {
        new Header(headerSelector);
    }
});