require('./scroll-slider');
require('./swipe-slider');
require('./rslider');
require('./hero-slider');
require('./advantages-slider');
require('./index-actions-slider');
require('./project-construction-progress-slider');
require('./included-slider');
require('./easy-to-start-slider');
require('./base-grid-slider');
