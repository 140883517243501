import {onDomChanges, onDomReady} from "../../components/dynamic/observer";

class MultiUploadableField {
    constructor(container) {
        this.container = container;
        this.input = this.container.querySelector('input[type=file]');
        this.filesList = this.container.querySelector('[data-multi-uploadable-list]');
        this.dt = new DataTransfer();

        this.bind();
    }

    bind() {
        this.input.addEventListener('change', () => {
            this.filesList.innerHTML = '';

            for (let i = 0; i < this.input.files.length; i++) {
                const file = this.input.files.item(i);
                const fileType = file.type;
                let newFileElement;
                switch (fileType) {
                    case 'application/octet-stream':
                    case 'application/pdf':
                    case 'application/x-pdf':
                    case 'application/zip':
                        newFileElement = this.createFileElement(file);
                        newFileElement.classList.add('input-file-item');

                        this.filesList.append(newFileElement);
                        this.dt.items.add(file);
                        this.bindRemoveLink(newFileElement);
                        break;
                    case 'image/jpeg':
                    case 'image/png':
                    case 'image/svg+xml':
                        this.dt.items.add(file);

                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.addEventListener('loadend', () => {
                            newFileElement = this.createImageElement(reader.result, file);
                            newFileElement.classList.add('input-file-item');
                            this.filesList.append(newFileElement);
                            this.bindRemoveLink(newFileElement);
                        }, { once: true });
                        break;
                }
            }
            this.input.files = this.dt.files;
        });
    }

    removeFilesItem(e) {
        const fileItem = e.target.closest('[data-file-item]');
        const name = fileItem.querySelector('[data-multi-uploadable-name]').innerHTML;

        fileItem.remove();
        for (let i = 0; i < this.dt.items.length; i++) {
            if (name === this.dt.items[i].getAsFile().name) {
                this.dt.items.remove(i);
            }
        }
        this.input.files = this.dt.files;
    }

    createFileElement(file) {
        const newFileElement = document.createElement('div');
        newFileElement.dataset.fileItem = '';
        newFileElement.innerHTML = '<span class="input-file-name" data-multi-uploadable-name>' + this.formatFileName(file.name) + '</span>' +
            '<a href="javascript:void(0);" class="input-file-list-remove" data-multi-uploadable-remove></a>';
        return newFileElement;
    }

    createImageElement(readerResult, file) {
        const newFileElement = document.createElement('div');
        newFileElement.dataset.fileItem = '';
        newFileElement.innerHTML = `<img class="input-file-list-img" src="${readerResult}">` +
            `<span class="input-file-name" data-multi-uploadable-name>${this.formatFileName(file.name)}</span>` +
            '<a href="javascript:void(0);" class="input-file-list-remove" data-multi-uploadable-remove></a>';
        return newFileElement;
    }

    bindRemoveLink(newFileElement) {
        const removeLink = newFileElement.querySelector('[data-multi-uploadable-remove]');
        removeLink.addEventListener('click', this.removeFilesItem.bind(this), { once: true });
    }


    formatFileName(fileName) {
        if (fileName.length > 45) {
            var re = /^.*\.(jpg|JPG|jpeg|png|gif|GIF|doc|DOC|pdf|PDF)$/i;
            var found = fileName.match(re);
            if (found[1] !== undefined) {
                return `${fileName.slice(0, 45)}...${found[1]}`;
            }
        }
        return fileName;
    }
}

const init = () => {
    document.querySelectorAll('[data-multi-uploadable]:not([data-initialized="true"])')
        .forEach((element) => {
            element.dataset.initialized = 'true';
            const multiUploadableField = new MultiUploadableField(element);
        });
};

onDomReady(() => init());
onDomChanges(() => init());