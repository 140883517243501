import {onDomReady} from "../../components/dynamic/observer";
import {Autoplay, Navigation, Pagination, Swiper} from 'swiper';
import RSlider from "../../components/rslider/rslider";

function init() {
    const element = document.querySelector('[data-hero-slider]');
    if (!element) {
        console.log('Could not find any element with data-hero-slider');
        return;
    }

    const prevArrow = document.querySelector('[data-hero-slider-prev]');
    const nextArrow = document.querySelector('[data-hero-slider-next]');
    const paginationElement = document.querySelector('[data-hero-pagination]');

    const slider = new Swiper(element, {
        modules: [Navigation, Pagination, Autoplay],
        speed: 1200,
        slidesPerView: 1,
        loop: true,
        preventInteractionOnTransition: true,
        grabCursor: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            waitForTransition: false
        },
        navigation: {
            prevEl: prevArrow,
            nextEl: nextArrow,
        },
        pagination: {
            el: paginationElement,
            type: 'bullets',
        },
    });

    const textElement = document.querySelector('[data-rslider="hero-text-slider"]');
    if (!textElement) {
        console.log('Could not find any element with data-hero-text-slider');
        return;
    }
    const textSlider = new RSlider(
        textElement,
        {
            onStart: ({ slideIn, slideFriendsIn }) => {
                slideFriendsIn.forEach((friendIn) => {
                    friendIn.classList.add('_active');
                })
                slideIn.classList.add('_active');
            },
            timeline: [
                RSlider.action({
                    // К слайдам докидывается:
                    // К появляющемуся: --rslider-first-act-in-progress: 0.2;
                    // К исчезающему: --rslider-first-act-out-progress: 0.2;
                    // easing ниже настраивается
                    name: 'next-act',
                    duration: 1200,
                    delay: 0,
                    // easeInOutCubic прогресс для "приходящего" слайда
                    // easingIn: (x) => x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2,
                    // linear прогресс для "уходящего" слайда
                    // easingOut: (x) => x,
                    onStart: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                        slideIn.classList.add('_active');
                        slideOut.classList.remove('_active');

                        slideIn.classList.add('_in-next-act');
                        slideOut.classList.add('_out-next-act');

                        slideFriendsIn.forEach((friendIn) => {
                            friendIn.classList.add('_active');
                            friendIn.classList.add('_in-next-act');
                        })

                        setTimeout(() => {
                            slideIn.classList.remove('_in-next-act');

                            slideFriendsIn.forEach((friendIn) => {
                                friendIn.classList.remove('_in-next-act');
                            })
                        }, 500);

                        slideFriendsOut.forEach((friendOut) => {
                            friendOut.classList.remove('_active');
                            friendOut.classList.add('_out-next-act');
                        })
                    },
                    onEnd: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                        slideOut.classList.remove('_out-next-act');

                        slideFriendsOut.forEach((friendOut) => {
                            friendOut.classList.remove('_out-next-act');
                        })
                    }
                }),
            ],
            timelineReverse: [
                RSlider.action({
                    name: 'prev-act',
                    duration: 1200,
                    delay: 0,
                    onStart: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                        slideIn.classList.add('_active');
                        slideOut.classList.remove('_active');

                        slideIn.classList.add('_in-prev-act');
                        slideOut.classList.add('_out-prev-act');

                        slideFriendsIn.forEach((friendIn) => {
                            friendIn.classList.add('_active');
                            friendIn.classList.add('_in-prev-act');
                        })

                        setTimeout(() => {
                            slideIn.classList.remove('_in-prev-act');

                            slideFriendsIn.forEach((friendIn) => {
                                friendIn.classList.remove('_in-prev-act');
                            })
                        }, 500);

                        slideFriendsOut.forEach((friendOut) => {
                            friendOut.classList.remove('_active');
                            friendOut.classList.add('_out-prev-act');
                        })
                    },
                    onEnd: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                        slideOut.classList.remove('_out-prev-act');

                        slideFriendsOut.forEach((friendOut) => {
                            friendOut.classList.remove('_out-prev-act');
                        })
                    }
                }),
            ]
        });

    slider.on('slideNextTransitionStart', () => {
        textSlider.next();
    });

    slider.on('slidePrevTransitionStart', () => {
        textSlider.prev();
    });
}

onDomReady(init);