import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Swiper, Pagination} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

function init() {
    const swipeSliderElement = document.querySelector('[data-advantages-slider]');
    if (!swipeSliderElement) {
        return;
    }

    const leftArrow = document.querySelector('[data-advantages-slider-left]');
    const rightArrow = document.querySelector('[data-advantages-slider-right]');
    const pagination = document.querySelector('[data-advantages-slider-pagination]');

    const slider = new Swiper(swipeSliderElement, {
        modules: [Navigation, Pagination],
        slidesPerView: "auto",
        navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
        },
        pagination: {
            el: pagination,
            type: "fraction",
        },
        breakpoints: {
            0: {
                spaceBetween: 20,
            },
            768: {
                spaceBetween: 40,
            },
            1280: {
                spaceBetween: 60,
            },
        },
        on: {
            init: (swiper) => {
                swiper.on('sliderFirstMove', (swiper) => {
                    swiper.$el[0].classList.remove('_active');
                });
                swiper.on('touchEnd', (swiper) => {
                    swiper.$el[0].classList.add('_active');
                });
            }
        }
    });
}

onDomReady(init);