import LiveEvent from '../live/live';

new LiveEvent('click', '[data-tab-link]', function smartTab(e) {
  const button = this;
  if (button.tagName === 'A') {
    e.preventDefault();
  }

  let selector = button.dataset.tab;
  if (!selector && button.getAttribute('href')) {
    selector = button.getAttribute('href');
  }

  const tab = document.querySelector(selector);
  if (tab) {
    tab.parentNode.querySelectorAll('[data-smart-content]')
        .forEach(item => item.classList.remove('_active'));
    tab.classList.add('_active');
  }
  button.closest('[data-smart-tabs]')
      .querySelectorAll('[data-tab-link]')
      .forEach((item) => {
        item.closest('li').classList.remove('_active');
      });
  button.closest('li').classList.add('_active');

  const event = new CustomEvent('smart-tabs-handle', { detail: { button, tab } });
  document.dispatchEvent(event);
});