export default class Office {
  constructor(itemElement) {
    this.settings = Object.assign({
      lat: 0,
      lng: 0,
      zoom: 0
    }, itemElement.dataset);
    this.itemElement = itemElement;
    this.placemark = undefined;
    this.closer = this.itemElement.querySelector('[data-close-office]');
    this.bindCloser();
  }

  getLat() {
    return parseFloat(this.settings.lat);
  }

  getLng() {
    return parseFloat(this.settings.lng);
  }

  setPlacemark(placemark) {
    this.placemark = placemark;
  }

  getPlacemark() {
    return this.placemark;
  }

  show() {
    this.itemElement.classList.add('_show');
  }

  hide() {
    this.itemElement.classList.remove('_show');
  }

  bindCloser() {
    if (!this.closer) {
      return;
    }
    this.closer.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.hide();
    });
  }
}