import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Swiper, Pagination} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';

function init()
{
    const swipeSliderElement = document.querySelector('[data-included-slider]');
    if (!swipeSliderElement) {
        return;
    }

    const leftArrow = document.querySelector('[data-included-slider-left]');
    const rightArrow = document.querySelector('[data-included-slider-right]');
    const pagination = document.querySelector('[data-included-slider-pagination]');

    const slider = new Swiper(swipeSliderElement, {
        modules: [Navigation, Pagination],
        slidesPerView: "auto",
        grabCursor: true,
        navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
        },
        pagination: {
            el: pagination,
            type: "fraction",
        },
        spaceBetween: 0,
        on: {
            init: (swiper) => {
                const arrows = swiper.pagination.$el[0].parentElement;
                if (arrows) {
                    if (swiper.snapGrid.length > 1) {
                        arrows.classList.remove('_hidden');
                    } else {
                        arrows.classList.add('_hidden');
                    }
                }
            }
        }
    });
}

onDomReady(init);