import {onDomReady} from "../../components/dynamic/observer";

onDomReady(() => {
    document.querySelectorAll('[data-domplaner-project]').forEach((project) => {
        const widgetCode = project.dataset.widgetCode;

        const regex = new RegExp('<!--.*-->|<script>|<\\/script>', 'g');
        const filteredWidgetCode = widgetCode.replaceAll(regex, '')
        if (filteredWidgetCode) {
            eval(filteredWidgetCode);

            const genplanInit = () => {
                const isGenplanVisible = project.querySelector('[data-domplaner-project-genplan]');
                if (isGenplanVisible) {
                    dplanloader(function(DP){
                        DP.insertGenplan('#dp-genplan');
                    });
                }
            }

            const layoutsInit = () => {
                const isLayoutsVisible = project.querySelector('[data-domplaner-project-layouts]');
                if (isLayoutsVisible) {
                    dplanloader(function (DP) {
                        DP.insertGrid('#dp-grid', {
                            blocks: ['1s', '1k', '2s', '2k', '3s', '3k', '4s', '4k'],
                            maxBlocks: 4
                        });
                    });
                }
            }

            genplanInit();
            layoutsInit();

            window.addEventListener('resize', () => {
                genplanInit();
                layoutsInit();
            })
        }
    });
});