import {onDomReady} from "../../components/dynamic/observer";
import {Navigation, Swiper, Pagination, Autoplay} from 'swiper';
import '../../../node_modules/swiper/swiper.scss';
import RSlider from "../../components/rslider/rslider";

class BaseGridSlider {
    sliders = [];
    constructor() {
        this.init();
        this.bind();
        this.eventListeners();
    }

    init() {
        document.querySelectorAll('[data-base-grid-slider-container]').forEach((container) => {
            const containerId = container.dataset.baseGridSliderContainer;
            this.sliders[containerId] = {
                imageSlider: undefined,
                titleSlider: undefined
            };

            const imageSliderElement = container.querySelector('[data-base-grid-image-slider]');
            if (imageSliderElement) {
                this.sliders[containerId].imageSlider = this.createImageSlider(imageSliderElement);
            }

            const titleSliderElement = container.querySelector('[data-rslider="base-grid-title-slider"]');
            if (imageSliderElement) {
                this.sliders[containerId].titleSlider = this.createTitleSlider(titleSliderElement);
            }
        });
    }

    bind() {
        Object.values(this.sliders).forEach((slider) => {
            if (slider.titleSlider === undefined || slider.imageSlider === undefined) {
                return;
            }

            slider.imageSlider.on('slideNextTransitionStart', () => {
                slider.titleSlider.next();
            });

            slider.imageSlider.on('slidePrevTransitionStart', () => {
                slider.titleSlider.prev();
            });
        });
    }

    createImageSlider(element) {
        const prevEl = element.querySelector('[data-base-grid-slider-prev]');
        const nextEl = element.querySelector('[data-base-grid-slider-next]');
        const paginationEl = element.querySelector('[data-base-grid-slider-pagination]');

        return new Swiper(element, {
            modules: [Navigation, Pagination, Autoplay],
            speed: 1200,
            slidesPerView: 1,
            loop: true,
            preventInteractionOnTransition: false,
            grabCursor: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
                waitForTransition: false,
            },
            navigation: {
                prevEl: prevEl,
                nextEl: nextEl,
            },
            pagination: {
                el: paginationEl,
                type: 'bullets',
            },
        });
    }

    createTitleSlider(element) {
        return new RSlider(
            element,
            {
                onStart: ({ slideIn, slideFriendsIn }) => {
                    slideFriendsIn.forEach((friendIn) => {
                        friendIn.classList.add('_active');
                    })
                    slideIn.classList.add('_active');
                },
                timeline: [
                    RSlider.action({
                        // К слайдам докидывается:
                        // К появляющемуся: --rslider-first-act-in-progress: 0.2;
                        // К исчезающему: --rslider-first-act-out-progress: 0.2;
                        // easing ниже настраивается
                        name: 'next-act',
                        duration: 1200,
                        delay: 0,
                        // easeInOutCubic прогресс для "приходящего" слайда
                        // easingIn: (x) => x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2,
                        // linear прогресс для "уходящего" слайда
                        // easingOut: (x) => x,
                        onStart: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                            slideIn.classList.add('_active');
                            slideOut.classList.remove('_active');

                            slideIn.classList.add('_in-next-act');
                            slideOut.classList.add('_out-next-act');

                            slideFriendsIn.forEach((friendIn) => {
                                friendIn.classList.add('_active');
                                friendIn.classList.add('_in-next-act');
                            })

                            setTimeout(() => {
                                slideIn.classList.remove('_in-next-act');

                                slideFriendsIn.forEach((friendIn) => {
                                    friendIn.classList.remove('_in-next-act');
                                })
                            }, 300);

                            slideFriendsOut.forEach((friendOut) => {
                                friendOut.classList.remove('_active');
                                friendOut.classList.add('_out-next-act');
                            })
                        },
                        onEnd: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                            slideOut.classList.remove('_out-next-act');

                            slideFriendsOut.forEach((friendOut) => {
                                friendOut.classList.remove('_out-next-act');
                            })
                        }
                    }),
                ],
                timelineReverse: [
                    RSlider.action({
                        name: 'prev-act',
                        duration: 1200,
                        delay: 0,
                        onStart: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                            slideIn.classList.add('_active');
                            slideOut.classList.remove('_active');

                            slideIn.classList.add('_in-prev-act');
                            slideOut.classList.add('_out-prev-act');

                            slideFriendsIn.forEach((friendIn) => {
                                friendIn.classList.add('_active');
                                friendIn.classList.add('_in-prev-act');
                            })

                            setTimeout(() => {
                                slideIn.classList.remove('_in-prev-act');

                                slideFriendsIn.forEach((friendIn) => {
                                    friendIn.classList.remove('_in-prev-act');
                                })
                            }, 300);

                            slideFriendsOut.forEach((friendOut) => {
                                friendOut.classList.remove('_active');
                                friendOut.classList.add('_out-prev-act');
                            })
                        },
                        onEnd: ({ slideIn, slideOut, slideFriendsIn, slideFriendsOut }) => {
                            slideOut.classList.remove('_out-prev-act');

                            slideFriendsOut.forEach((friendOut) => {
                                friendOut.classList.remove('_out-prev-act');
                            })
                        }
                    }),
                ]
            });
    }

    eventListeners() {
        document.addEventListener('smart-tabs-handle', (e) => {
            const containerElement = e.detail.tab.querySelector('[data-base-grid-slider-container]');

            if (containerElement !== null) {
                const containerId = containerElement.dataset.baseGridSliderContainer;

                for (var i = 0; i < Object.keys(this.sliders).length; i++) {
                    const key = Object.keys(this.sliders)[i];
                    const sliderObject = this.sliders[key];

                    if (sliderObject.imageSlider === undefined || sliderObject.imageSlider.$el[0] === undefined) {
                        continue;
                    }

                    if (key === containerId) {
                        sliderObject.imageSlider.destroy();
                        delete this.sliders[key];
                        sliderObject.titleSlider.slides.forEach(slide => slide.slide.classList.remove('_active'));

                        this.sliders[containerId] = {
                            imageSlider: undefined,
                            titleSlider: undefined
                        };

                        const imageSliderElement = containerElement.querySelector('[data-base-grid-image-slider]');
                        if (imageSliderElement) {
                            this.sliders[containerId].imageSlider = this.createImageSlider(imageSliderElement);
                        }

                        const titleSliderElement = containerElement.querySelector('[data-rslider="base-grid-title-slider"]');
                        if (imageSliderElement) {
                            this.sliders[containerId].titleSlider = this.createTitleSlider(titleSliderElement);
                        }
                    }
                }

                this.bind();
            }
        });
    }
}

onDomReady(() => {
    const baseGridSlider = new BaseGridSlider();
});