import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";

/**
 *
 *  Локальные модалочки
 *
 */

new LiveEvent('click', '[data-modal]', function openModal(e) {
  e.preventDefault();
  let link = this;
  let modal = new Modal(this, {
    animation: {
      classIn: '_open',
      classOut: '_close',
      timeoutIn: 16,
      timeoutOut: 16,
    },
    closerText: '',
    theme: this.dataset.theme ?? 'default',
    onAfterOpen: function () {
    },
    onBeforeClose: function () {
    },
    onFormSuccess: function () {
      const submitEvent = new Event('modal-form:success');
      submitEvent.element = link;
      document.dispatchEvent(submitEvent);
    },
  });
  return false;
});