import noUiSlider from 'nouislider';
import _ from 'lodash';
import wNumb from 'wnumb';
import axios from 'axios';
import {onDomReady} from "../../components/dynamic/observer";

class CreditCalc {
  constructor(rootElement, settings) {
    this.rootElement = rootElement;
    this.formId = this.rootElement.dataset.formId;
    this.settings = settings;
    this.values = {
      sum: 0,
      fee: 0,
      term: 0,
      rate: 0,
    };

    this.initInputs();
    this.initValues();
    this.bindInputs();

    this.updateFeeRangeInputSlider();
    this.setFeePercentInnerHTML();
    this.debouncedRender();
  }

  initInputs() {
    this.sumInput = this.rootElement.querySelector('[data-credit-calc-sum-input] [data-range-single-input]');
    this.feeInput = this.rootElement.querySelector('[data-credit-calc-fee-input] [data-range-single-input]');
    this.termInput = this.rootElement.querySelector('[data-credit-calc-term-input] [data-range-single-input]');
    this.rateInput = this.rootElement.querySelector('[data-credit-calc-rate-input]');

    this.sumFormInput = this.rootElement.querySelector(`input[id="${this.formId}_price"]`);
    this.feeFormInput = this.rootElement.querySelector(`input[id="${this.formId}_firstPay"]`);
    this.termFormInput = this.rootElement.querySelector(`input[id="${this.formId}_term"]`);
    this.rateFormInput = this.rootElement.querySelector(`input[id="${this.formId}_rate"]`);
    this.monthPayFormInput = this.rootElement.querySelector(`input[id="${this.formId}_monthPay"]`);
  }

  initValues() {
    this.values = Object.assign(this.values, {
      sum: this.stringToNumber(this.sumInput.value),
      fee: this.stringToNumber(this.feeInput.value),
      term: this.stringToNumber(this.termInput.value),
      rate: this.stringToNumber(this.rateInput.value),
    });

    this.sumFormInput.value = this.values.sum;
    this.feeFormInput.value = this.values.fee;
    this.termFormInput.value = this.values.term;
    this.rateFormInput.value = this.values.rate;
  }

  bindInputs() {
    this.sumInput.addEventListener('change', _.debounce((e) => {
      this.values.sum = this.stringToNumber(e.target.value);
      this.sumFormInput.value = this.stringToNumber(e.target.value);
      this.debouncedRender();
      this.updateFeeRangeInputSlider();
    }, 1000));

    this.feeInput.addEventListener('change', _.debounce((e) => {
      this.values.fee = this.stringToNumber(e.target.value);
      this.feeFormInput.value = this.stringToNumber(e.target.value);
      this.setFeePercentInnerHTML();
      this.debouncedRender();
    }, 1000));

    this.termInput.addEventListener('change', _.debounce((e) => {
      this.values.term = this.stringToNumber(e.target.value);
      this.termFormInput.value = this.stringToNumber(e.target.value);
      this.debouncedRender();
    }, 1000));

    this.rateInput.addEventListener('change', _.debounce((e) => {
      this.values.rate = this.stringToNumber(e.target.value);
      this.rateFormInput.value = this.stringToNumber(e.target.value);
      this.debouncedRender();
    }, 1000));
  }

  debouncedRender = _.debounce(() => {
    this.renderValues();
  }, 100);

  getCreditSumAsNumber() {
    return this.values.sum - this.values.fee;
  }

  setFeePercentInnerHTML() {
    const feePercentEl = this.feeInput.parentNode.querySelector('[data-range-single-input-percent] span');
    if (feePercentEl) {
      const feePercent = Math.ceil(this.values.fee / (this.values.sum / 100)).toFixed(0);
      feePercentEl.innerHTML = feePercent.toString();
    }
  }

  updateFeeRangeInputSlider() {
    if (window.rangeInputSliders && window.rangeInputSliders.hasOwnProperty('range-fee')) {
      window.rangeInputSliders['range-fee'].slider.updateOptions({
        range: {
          'min': window.rangeInputSliders['range-fee'].slider.options.range.min,
          'max': this.values.sum,
        }
      });
    }
  }

  stringToNumber(value) {
    return parseInt(value.replaceAll(' ', ''), 10);
  }

  async renderValues() {
    let monthPay = 0;

    try {
      const response = await axios.post(this.rootElement.dataset.url, {
        sum: this.getCreditSumAsNumber(),
        term: this.values.term,
        rate: this.values.rate
      });
      if (response.data.hasOwnProperty('monthPay')) {
        monthPay = response.data.monthPay;
        this.monthPayFormInput.value = response.data.monthPay;
      }
    } catch (e) {
      console.error(e);
    }
    this.rootElement.querySelector('[data-credit-calc-month-pay]').innerHTML = monthPay.toLocaleString();
  }
}

onDomReady(() => {
  const el = document.querySelector('[data-credit-calc]');
  if (el) {
    const calc = new CreditCalc(el);
  };
});